/* .custom-modal-child {
  background-color: #01c411;
} */

.custom-modal {
  position: fixed;
  overflow-x: hidden !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.65);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2em 1em;
  z-index: 999999;
  box-sizing: border-box;

  @media (max-width: 575px) {
    padding: 0;
  }
}

/* .custom-modal-child > div {
  background-color: #0a2631;
} */

.custom-modal.custom-modal-fade {
  animation: fade-in 1s 1 linear;
  animation-fill-mode: forwards;
  opacity: 0;
}

.custom-modal > .custom-modal-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.custom-modal-close {
  color: #5e5e5e;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.749);
  background: #0a2631;
  width: 25px;
  height: 25px;
  z-index: 999999;
  margin-right: 20px;
  margin-top: 10px;
  box-sizing: border-box;
  float: right;
  text-align: center;
  position: relative;

  .icon-close,
  .svg-icon-minimize {
    background: #0a2631;
  }
}

@keyframes fade-in {
  0% {
    animation-timing-function: cubic-bezier(0.2242, 0.7499, 0.3142, 0.8148);
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media (max-width: 950px) {
  .modal-child {
    max-width: 99vw;
  }
}
